<template>
  <header id="header">
    <div class="header-img">
      <router-link to="/">
        <transition mode="out-in" name="fade-left">
          <component :is="logoEmpresa" />
        </transition>
      </router-link>
    </div>
    <div class="btn-header">
      <div class="header-layout">
        <input id="modo-layout" type="checkbox" v-model="modoLayout" />
        <label class="toggleLayout" for="modo-layout" :class="{ active: modoLayout }"></label>
      </div>
      <div class="header-user">
        <a @click="deslogar">Sair</a>
      </div>
    </div>
  </header>
</template>

<script>
import LogoPositivo from '@/components/elementos/logos/LogoPositivo.vue';
import LogoNegativo from '@/components/elementos/logos/LogoNegativo.vue';
export default {
  components: {
    LogoPositivo,
    LogoNegativo,
  },
  data() {
    return {
      modoLayout: false,
    };
  },
  computed: {
    corLayout() {
      return this.modoLayout == false ? 'layout-claro' : 'layout-escuro';
    },
    logoEmpresa() {
      return this.modoLayout == false ? 'LogoPositivo' : 'LogoNegativo';
    },
  },
  methods: {
    deslogar() {
      this.$store.commit('user/SET_LOGADO', false);
      this.$store.commit('user/SET_ID_USUARIO', '');
      this.$store.commit('relatorios/SET_RELATORIO_USUARIOS', null);
      this.$store.commit('relatorios/SET_RELATORIO_FALE_CONOSCO', null);
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('powerbi');
      window.localStorage.removeItem('user-name');
      window.localStorage.removeItem('perfil');
      this.$router.push('/login');
    },
    setarLayout() {
      const modoLayout = window.localStorage.getItem('corLayout');
      if (modoLayout) {
        if (modoLayout == 'layout-claro') {
          this.modoLayout = false;
          return this.$store.commit('SET_TEMA', modoLayout);
        } else {
          this.modoLayout = true;
          return this.$store.commit('SET_TEMA', modoLayout);
        }
      }
    },
  },
  watch: {
    corLayout() {
      window.localStorage.setItem('corLayout', this.corLayout);
      return this.$store.commit('SET_TEMA', this.corLayout);
    },
  },
  created() {
    this.$store.commit('SET_SIDEBAR', true);
    this.setarLayout();
  },
};
</script>

<style lang="scss">
#header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  transition: 0.3s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  .header-img {
    position: relative;
    box-sizing: border-box;
    width: 220px;
    height: 100%;
    padding: 10px 15px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }

  @media (max-width: 700px) {
    height: 55px;

    .header-img {
      width: 180px;
    }
  }
}

.btn-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  transition: 0.3s;

  @media (max-width: 340px) {
    padding-left: 20px;
    background: none !important;
  }
}

.header-layout {
  input {
    display: none;
  }

  .toggleLayout {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 60px;
    height: 35px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid $cinza-1;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    &:after {
      position: absolute;
      top: 50%;
      left: 5px;
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      content: '';
      transition: 0.3s;
      transform: translateY(-50%);
      border-radius: 50%;
      background: $laranja-1 url('~@/assets/images/icones/layout-claro.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 17px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &.active:after {
      left: calc(100% - 30px);
      background: lighten($laranja-1, 5%) url('~@/assets/images/icones/layout-escuro.svg');
      background-repeat: no-repeat;
      background-position: 6px 4px;
      background-size: 12px;
    }
  }
}

.header-user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px 0 30px;

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 700px) {
    margin: 0 20px 0 15px;
  }
}
</style>
